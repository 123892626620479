@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,700;0,800;1,400;1,500;1,600;1,700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,400&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Playfair Display", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #00263a !important;
}

::-webkit-scrollbar {
  width: 8px;
  border-radius: 9999px;
  -webkit-overflow-scrolling: touch;
}

::-webkit-scrollbar-thumb {
  background-color: #33c7b6;
  border-radius: 9999px;
}

::-webkit-scrollbar:horizontal {
  height: 8px;
  border-radius: 9999px;
  background-color: white;
  -webkit-overflow-scrolling: touch;
}

::-webkit-scrollbar-thumb:horizontal {
  background-color: #33c7b6;
  border-radius: 9999px;
}

@media only screen and (max-width: 768px) {
  ::-webkit-scrollbar {
    height: 4px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: 54px;
}

@media only screen and (max-width: 1024px) {
  h1 {
    font-size: 43px !important;
  }
}

@media only screen and (max-width: 1024px) {
  h2 {
    font-size: 35px;
  }
}

h2 {
  font-size: 44px;
}

@media only screen and (max-width: 1024px) {
  h3 {
    font-size: 24px !important;
  }
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 28px;
}

h5 {
  font-size: 24px;
}

h6 {
  font-size: 20px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.previousButton {
  border-width: 1px;
  border-radius: 50px;
  border-color: white;
  padding: 0.4rem;
  align-items: center;
  justify-content: center;
  display: flex;
  line-height: 11px;
  font-size: 18px;
}

.nextButton {
  border-width: 1px;
  border-radius: 50px;
  border-color: white;
  padding: 0.4rem;
  align-items: center;
  justify-content: center;
  display: flex;
  line-height: 11px;
  font-size: 18px;
}

.selected {
  border-bottom: 1px solid;
  border-color: white;
}

.hidden {
  display: none;
}

.MuiButtonBase-root {
  padding: 0 !important;
  display: flex !important;
  align-items: top !important;
  height: 100%;
}

#close {
  right: 12%;
  position: absolute;
  top: 15%;
  color: white;
  width: 10vw;
  height: 7vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: transparent;
  cursor: pointer;
}

#close-x {
  height: auto;
  width: 30px;
  position: absolute;
  background: transparent;
}

#close-text {
  color: black;
  font-family: "Playfair Display", serif;
  font-weight: 700;
  font-size: 14px;
  background: transparent;
}

.img-bigger {
  height: auto;
  width: 35px !important;
}

#inscription {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* cookiebot */
#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
  background-color: #33c7b6 !important;
  border-color: #33c7b6 !important;
}

#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
  border-color: #33c7b6 !important;
}

.CybotCookiebotDialogBodyButton {
  border-color: #33c7b6 !important;
}

#CybotCookiebotDialogBodyEdgeMoreDetails a {
  color: #33c7b6 !important;
}

#CybotCookiebotDialog
  input:checked
  + .CybotCookiebotDialogBodyLevelButtonSlider {
  background-color: #33c7b6 !important;
}

.CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
  color: #33c7b6 !important;
  border-bottom: 3px solid #33c7b6 !important;
}

.CybotCookiebotDialogNavItemLink:hover {
  color: #33c7b6 !important;
}

#CybotCookiebotDialogDetailBodyContentTextAbout a {
  color: #33c7b6 !important;
}

#CybotCookiebotDialog label:hover {
  color: #33c7b6 !important;
}

.CybotCookiebotDialogDetailBodyContentCookieProvider:not(
    .CybotCookiebotDialogDetailBodyContentCookieInfoCount
  ):hover {
  color: #33c7b6 !important;
}

#CybotCookiebotDialogDetailBodyContentCookieContainerUnclassified:hover {
  color: #33c7b6 !important;
}

#CookiebotWidget-btn-withdraw {
  border-color: #33c7b6 !important;
}

#CookiebotWidget-btn-change {
  background-color: #33c7b6 !important;
  border-color: #33c7b6 !important;
}

.CookiebotWidget-consent-details {
  border-color: #33c7b6 !important;
}

.CookiebotWidget-body
  .CookiebotWidget-consents-list
  li.CookiebotWidget-approved
  svg {
  fill: #33c7b6 !important;
}

.CybotCookiebotDialogBodyLevelButtonDescription:hover {
  color: #33c7b6 !important;
}

.CookiebotWidget-consent-details button {
  color: #33c7b6 !important;
}

.CybotCookiebotDialogBodyLevelButtonDescription:hover {
  color: #33c7b6 !important;
}

#CybotCookiebotDialogDetailBodyContentCookieContainerPreference:hover {
  color: #33c7b6 !important;
}
#CybotCookiebotDialogDetailFooter a {
  color: #33c7b6 !important;
}

.CybotCookiebotDialogDetailBodyContentCookieLink, #CybotCookiebotDialogDetailBodyContentTextAbout a {
  color: #33c7b6 !important;
}

#CybotCookiebotDialogDetailBodyContentCookieContainerStatistics:hover {
  color: #33c7b6 !important;
}

#CybotCookiebotDialogDetailBodyContentCookieContainerAdvertising:hover{
  color: #33c7b6 !important;
}

#CybotCookiebotDialogDetailBodyContentCookieContainerNecessary:hover {
  color: #33c7b6 !important;
}

.fullscreen {
  padding-bottom: env(safe-area-inset-bottom);
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: transparent !important;
}
